import {
    tss,
} from 'app/tss'

export default tss.create(({
    theme,
}) => {
    return {
        button: {
            whiteSpace: 'nowrap',
            minWidth: '170px !important',
            maxWidth: '240px !important',
            backgroundColor: `${theme.palette.primary.light} !important`,
            color: 'white !important',
            height: '36px !important',
            fontSize: `${theme.typography.body1.fontSize} !important`,
            margin: `${theme.spacing(0.7)} !important`,
        },
        layerContent: {
            flexWrap: 'wrap',
            alignContent: 'center',
            justifyContent: 'center',
            display: 'flex',
            height: '100%',
            overflowY: 'auto',
        },
        link: {
            display: 'inline-block',
            width: '100%',
            height: '100%',
            lineHeight: '24px',
            color: theme.palette.common.white,
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    }
})
