import {
    SkyNetTableTab,
} from 'app/shared-components/SkyNetTable/types/skyNetTable.types'
import {
    RegionSecondLevel,
    RegionFirstLevel,
    PickupFeeType,
} from 'app/types/enums'
import {
    FilterType,
} from 'app/types/common.enums'
import {
    RequestKeys,
} from 'app/hooks/useRequest'
import {
    RateCardsTabs,
} from '../RateCards.types'
import requests from './LogisticsCosts.request'
import {
    LogisticsCostsItem,
} from './LogisticsCosts.types'

const getAvailabilityOrCost = (availability: PickupFeeType, cost: number) => {
    if (availability === PickupFeeType.CHARGE) { return cost }
    if (availability === PickupFeeType.FREE) { return '-' }
    return 'Not Available'
}

export default function getLogisticsCostsConfig(): SkyNetTableTab<keyof LogisticsCostsItem> {
    return {
        name: 'Logistic Costs',
        url: RateCardsTabs.LogisticsCosts,
        customUrl: requests[RequestKeys.logisticsCostsFilterRequest]().url,
        fields: [
            {
                name: 'regionFirstLevel',
                labelKey: 'Country.regionFirstLevel',
                componentName: 'EnumValue',
                allowedValuesEnum: RegionFirstLevel,
                filterField: 'regionFirstLevel',
                filterType: FilterType.Array,
                width: '20%',
            },
            {
                name: 'regionSecondLevel',
                labelKey: 'Country.regionSecondLevel',
                componentName: 'EnumValue',
                allowedValuesEnum: RegionSecondLevel,
            },
            {
                name: 'countries',
                labelKey: 'LogisticsCostsRateCard.countries',
                mapCellProps: ({
                    countries,
                }: LogisticsCostsItem) => {
                    return {
                        children: countries?.join(', '),
                    }
                },
                width: '30%',
                sorting: false,
            },
            {
                name: 'originCostPickUpCustomerAvailability',
                labelKey: 'LogisticsCostsRateCard.originCostByCustomer',
                mapCellProps: ({
                    originCostPickUpCustomerAvailability,
                    originCostPickUpCustomer,
                }: LogisticsCostsItem) => {
                    return {
                        children: getAvailabilityOrCost(originCostPickUpCustomerAvailability,
                            originCostPickUpCustomer),
                    }
                },
                sorting: false,
            },
            {
                name: 'originCostLtlAvailability',
                labelKey: 'LogisticsCostsRateCard.originCostLtl',
                mapCellProps: ({
                    originCostLtlAvailability,
                    originCostLtl,
                }: LogisticsCostsItem) => {
                    return {
                        children: getAvailabilityOrCost(originCostLtlAvailability,
                            originCostLtl),
                    }
                },
                sorting: false,
            },
            {
                name: 'originCostFtlAvailability',
                labelKey: 'LogisticsCostsRateCard.originCostFtl',
                mapCellProps: ({
                    originCostFtlAvailability,
                    originCostFtl,
                }: LogisticsCostsItem) => {
                    return {
                        children: getAvailabilityOrCost(originCostFtlAvailability,
                            originCostFtl),
                    }
                },
                sorting: false,
            },
            {
                name: 'reverseCostAvailability',
                labelKey: 'LogisticsCostsRateCard.reverseConst',
                mapCellProps: ({
                    reverseCostAvailability,
                    reverseCost,
                }: LogisticsCostsItem) => {
                    return {
                        children: getAvailabilityOrCost(reverseCostAvailability,
                            reverseCost),
                    }
                },
                sorting: false,
            },
            {
                name: 'collectionLtlAvailability',
                labelKey: 'LogisticsCostsRateCard.collectionBySkyCellLTL',
                mapCellProps: ({
                    collectionLtlAvailability,
                    collectionLtl,
                }: LogisticsCostsItem) => {
                    return {
                        children: getAvailabilityOrCost(collectionLtlAvailability,
                            collectionLtl),
                    }
                },
                sorting: false,
            },
            {
                name: 'collectionFtlAvailability',
                labelKey: 'LogisticsCostsRateCard.collectionBySkyCellFTL',
                mapCellProps: ({
                    collectionFtlAvailability,
                    collectionFtl,
                }: LogisticsCostsItem) => {
                    return {
                        children: getAvailabilityOrCost(collectionFtlAvailability,
                            collectionFtl),
                    }
                },
                sorting: false,
            },
        ],
    }
}
